export function useUserSupport() {
  const user = useCurrentUser();
  const supportEmail = 'support@tastenetwork.io';
  const supportMailto = `mailto:${supportEmail}`;

  const memberSupportEmailLink = computed(() => {
    const subject = `Taste Network Support - Sampler ID: ${user.value?.uid}`;
    const encodedSubject = encodeURIComponent(subject);

    return `${supportMailto}?subject=${encodedSubject}`;
  });

  const brandSupportEmailLink = computed(() => {
    const subject = `Taste Network Brand Support - Brand User ID: ${user.value?.uid}`;
    const encodedSubject = encodeURIComponent(subject);

    return `${supportMailto}?subject=${encodedSubject}`;
  });

  return {
    supportEmail,
    supportMailto,
    memberSupportEmailLink,
    brandSupportEmailLink,
  };
}
